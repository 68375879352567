.WhiskyDetails {
  background-color: rgba(165, 165, 165, 0.25);
  padding: 5px 0px 5px 0px;
}

p {
  font-weight: normal;
  padding: 0px 5px 0px 5px;
}

th {
  vertical-align: top;
}