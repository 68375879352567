.Filter {
  position: sticky;
  top: 0px;
  padding-left: 50px;
  grid-column-start: 2;
  grid-column-end: 3;
}

input, select {
  margin: 15px 0px 15px 0px;
  font-size: 150%;
  width: 250px;
  color: #3e3e3e;
}