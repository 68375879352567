.Whisky {
  font-weight: bold;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.2) 100%);
  grid-column-start: 2;
  grid-column-end: 3;
}

.container {
  width: 100%;
  padding: 5px 0px 5px 0px;
}
