.WhiskyName {
  grid-column-start: 2;
  grid-column-end: 3;
}

.item {
  padding-bottom: 10px;
}

.container {
  width: 100%;
  padding: 5px 0px 5px 0px;
}

.left {
  float: left;
}

.centre {
  border-bottom: 1px dotted #e4e4e4;
  display: block;
  overflow: auto;
}

.right {
  float: right;
}

button {
  border-radius: 8px;
}
