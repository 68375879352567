body {
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.5) 100%), url("./background.png");
}

.App {
  text-align: left;
  color: #e4e4e4;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}

@media only screen and (max-width: 700px) {
  .App {
    grid-template-columns: 0fr 1fr 0fr;
  }
}